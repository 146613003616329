import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';

import flutter from '../Images/flutter.png';
import node1 from '../Images/node1.png';
import react from '../Images/react.png';
import mongo from '../Images/mongo.png';



function Fullstack() {
    return (
        <>

            <div className='a1'>
                <h6 className='a2'>Why GT?</h6>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <h2 className='a3'>With 7 years of software and mobile application development experience,
                            we train students like how "A software company train their freshers"</h2>
                    </Col>
                    <Col md={3}></Col>
                </Row><br /><br />
                <Container>
                    <Row>
                        <Col md={3}>
                            <div className='a4'>
                                <h2 className='a5'>1 on 1 Training</h2>
                                <p className='a6'>Individual care by developers</p>

                            </div>

                        </Col>
                        <Col md={3}>
                            <div className='a4'>
                                <h2 className='a5'>Full day classes</h2>
                                <p className='a6'>Time is valuable for students</p>

                            </div>

                        </Col>
                        <Col md={3}>
                            <div className='a4'>
                                <h2 className='a5'>Live projects</h2>
                                <p className='a6'>Students develop multiple live apps</p>

                            </div>

                        </Col>
                        <Col md={3}>
                            <div className='a4'>
                                <h2 className='a5'>Pure offline</h2>
                                <p className='a6'>Develop projects as a community</p>

                            </div>

                        </Col>

                    </Row>
                    <br /><br />
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Row>
                                <Col><br /><img src={flutter} className='c1' /></Col>
                                <Col><br /><img src={react} className='a21' /></Col>
                                <Col><img src={node1} className='a21' /></Col>
                                <Col><br /><br /><img src={mongo} className='a21' /></Col>

                            </Row>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
                <br /><br />
            </div>

            {/* _____________________________________Black ends____________________________________________ */}
            {/* <br /><br />
            <h6 className='a20'>Fullstack Academy</h6>
            <Row>
                <Col md={3}></Col>
                <Col md={6}>
                    <h1 className='a19'>Our bootcamps prepare you to succeed in tech and beyond.</h1>
                </Col>
                <Col md={3}></Col>
            </Row><br />

            <Row>
                <Col md={2}></Col>
                <Col md={8}>
                    <Row>
                        <Col md={6} >
                            <Card className='a24'>
                                <Card.Img variant="top" src={cardimg} className='a23'/>
                                <Card.Body>
                                    <Card.Title>Full-Time Coding Bootcamp</Card.Title>
                                    <Card.Text>
                                        <button className='a22'>Online</button>
                                        <button className='a22'>Full-time</button>
                                    </Card.Text>
                                    <hr />
                                    <div className="d-grid gap-2">
                                        <Button variant="light" size="lg">
                                            Learn coding full-time <i class="bi bi-arrow-right"></i>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} >
                            <Card className='a24'>
                                <Card.Img variant="top" src={cardimg} className='a23'/>
                                <Card.Body>
                                    <Card.Title>Full-Time Coding Bootcamp</Card.Title>
                                    <Card.Text>
                                        <button className='a22'>Online</button>
                                        <button className='a22'>Full-time</button>
                                    </Card.Text>
                                    <hr />
                                    <div className="d-grid gap-2">
                                        <Button variant="light" size="lg">
                                            Learn coding full-time <i class="bi bi-arrow-right"></i>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} >
                            <Card className='a24'>
                                <Card.Img variant="top" src={cardimg} className='a23'/>
                                <Card.Body>
                                    <Card.Title>Full-Time Coding Bootcamp</Card.Title>
                                    <Card.Text>
                                        <button className='a22'>Online</button>
                                        <button className='a22'>Full-time</button>
                                    </Card.Text>
                                    <hr />
                                    <div className="d-grid gap-2">
                                        <Button variant="light" size="lg">
                                            Learn coding full-time <i class="bi bi-arrow-right"></i>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} >
                            <Card className='a24'>
                                <Card.Img variant="top" src={cardimg} className='a23'/>
                                <Card.Body>
                                    <Card.Title>Full-Time Coding Bootcamp</Card.Title>
                                    <Card.Text>
                                        <button className='a22'>Online</button>
                                        <button className='a22'>Full-time</button>
                                    </Card.Text>
                                    <hr />
                                    <div className="d-grid gap-2">
                                        <Button variant="light" size="lg">
                                            Learn coding full-time <i class="bi bi-arrow-right"></i>
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={2}></Col>
            </Row> */}

        </>
    );
}

export default Fullstack;


