import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Card, Container } from 'react-bootstrap';

function Help() {
    return (
        <>
            <div className='n1 p-3'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <br />
                            <Row>
                                <Col md={6} className='n2 text-center'>Mobile full stack development</Col>
                                <Col md={6} className='n2 text-center'>Web full stack development</Col>
                                <Col md={6} className='n2 text-center'>Web front-end</Col>
                                <Col md={6} className='n2 text-center'>Mobile front-end</Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <div className='n4'>
                                <h1 className='n3'>Let's help you navigate your next</h1>
                                <a href='https://wa.me/918667641134?text=Hii..' target='_blank'>
                                    <button className='n5'>Contact us</button>
                                </a>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Help;