import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col} from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import RCB1 from '../Images/RCB1.jpg';
import NIB from '../Images/NIB.jpg';

function Branch(){
    return(
        <>  
            <div className="py-5 a11">
            <Container>
                <h3 className="text-center mb-5">Our Branches</h3>
                <Row>
                    <Col md={3}>
                    <div className="center"><img src={RCB1} className="a12"/></div>
                    </Col>
                    <Col md={3} className="a13">
                    <h5 className="my-3">Race Course Branch(RCB)</h5>

                    <div className="cen">
                        <p><b>Address:-</b>A4, Rathinam Circle View, Race Course, Coimbatore, Tamil Nadu-  641018</p>
                        <p><b>Phone:-</b>8667641134</p>
                    </div>
                    </Col>




                    <Col md={3}>
                    <div className="center"><img src={NIB} className="a12"/></div>
                    </Col>
                    <Col md={3}>
                    <h5 className="my-3">Nava India Branch(NIB)</h5>
                    <div className="cen">
                        <p><b>Address:-</b>No.336/2, 6th Floor, Mayflower Valencia, Nava India,
                        Peelamedu, Coimbatore, Tamil Nadu - 641004</p>
                        <p><b>Phone:-</b>8667390454</p>
                    </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}

export default Branch;