import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { Container, Button, Row, Col, Card, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';


function Enquiry() {

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        yop: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            const onlyNumber = value.replace(/[^0-9]/g, '');
            if (onlyNumber.length <= 10) {
                setFormData({ ...formData, [name]: onlyNumber });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const clientKey = "bf8913c1-fb8a-43b6-be00-f36387e80dda";
    const apiKey = "09574e8c-94d7-4309-a566-43faf0712d4f";
    const uri = "https://sms.nettyfish.com/api/v2/SendSMS?";
    const senderId = "ELVADO";
    const number = "8667641134";
    const greet = "Sir/Madam, New Visitor to GT Site"
    const sendOtpSms = async (appName, number1) => {
        const template = `Hello ${greet}, Thank you for visiting GTwebsite. For any further details, Please contact at ${appName},${number1}. ELVADO`
        const parameters = `${uri}SenderId=${senderId}&Message=${encodeURIComponent(template)}&MobileNumbers=${number}&ApiKey=${apiKey}&ClientId=${clientKey}`;

        axios.get(parameters).then(response => { console.log('SMS sent successfully:', response.data) })
            .catch(error => { console.error('Error sending SMS:', error) });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendData = {
            sender: { email: 'prabakarnode@gmail.com' },
            to: [{ email: 'navaneedhan.law@gmail.com' }],
            subject: 'Request from site',
            htmlContent: `
                <html>
                    <body>
                        <p>You have a new request from Website</p>
                        <p>Name : ${formData.name}</p>
                        <p>Phone No : ${formData.phone}</p>
                        <p>Year of Passing : ${formData.yop}</p>
                    </body>
                </html>`
        };
        try {
            // Send Email using Brevo API
            await axios.post('https://api.brevo.com/v3/smtp/email', sendData, {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': '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',  // Replace with your Brevo API key
                },
            });


            await sendOtpSms(formData.name, formData.phone);
            // Success Toast
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "success",
                title: "Request Sent Successfully"
            });

            // Reset form data
            setFormData({
                name: "",
                phone: "",
                yop: "",
            });

        } catch (error) {
            // Error Toast
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: "Request Not Sent"
            });
        }
    };

    return (
        <>
            <div className='e1'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <h1 className='e3'>Join our full-day, 1:1 training in Data science,
                                Machine learning, Web & Mobile full stack development courses.  </h1>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <h4 className='e2'>Book a Session, For Free!</h4><br />
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Full name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange} /><br />

                                        <Form.Control
                                            required
                                            type="tel"
                                            placeholder="Enter mobile number"
                                            maxLength={10}
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange} /><br />

                                        <Form.Control
                                            required
                                            type="tel"
                                            placeholder="Year of passing"
                                            name="yop"
                                            value={formData.yop}
                                            onChange={handleInputChange}
                                        /><br />
                                        <div className='text-center'>
                                            <Button variant="secondary" type='submit'>Submit</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    );
}

export default Enquiry;