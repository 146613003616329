import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import "bootstrap-icons/font/bootstrap-icons.css";

function Mobweb() {
    return (
        <>
        <div className='a26'> 
            <Container>
                <h1 className='a14'>CRAVE YOUR</h1>
                <h1 className='a15'>own career path</h1>


                <Row className='a18'>

                    <Col md={4} >
                        <div className='a16'>
                            <h1><img src={icon1} className='a17'/>  3 Months</h1>
                            <h5>graduates working in tech around the world</h5>
                        </div>
                        <h4 className='a18'>Join our global community</h4>
                    </Col>
                    <Col md={4}>
                        <div className='a16'>
                            <h1><img src={icon1} className='a17' />  6 Months</h1>
                            <h5>graduates working in tech around the world</h5>
                        </div>
                        <h4 className='a18'>Join our global community</h4>
                    </Col>
                    <Col md={4}>
                        <div className='a16'>
                            <h1><img src={icon1} className='a17' />  12 Months</h1>
                            <h5>graduates working in tech around the world</h5>
                        </div>
                        <h4 className='a18'>Join our global community</h4>
                    </Col>
                </Row>
                <div className="d-grid gap-2">
                    <Button variant="secondary" size="lg">
                        Get career advice <i class="bi bi-arrow-right"></i>
                    </Button>
                </div>
                <br/><br/>
            </Container>
            </div>
        </>
    );
}

export default Mobweb;