import { Row, Col} from 'react-bootstrap';

function Start() {
    return (
        <>
            

                <Row className='m1'>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <h1 className='m3'>Start-up with "GT"</h1>

                        <p className='m4'>Create your own startup application with an integrated admin panel, 
                        developed from the ground up, and deploy it on both Google Play Store and Apple App Store.</p>
                    </Col>
                    <Col md={2}></Col>
                </Row>

            
        </>
    );
}

export default Start;